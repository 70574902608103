<template>
  <div style="max-width: 900px">
    <h2 class="mb-5">
      Backends
      <v-btn
        outlined
        class="v-btn-icon float-right"
        color="secondary"
        :to="{ name: 'backend', params: { id: 'new' } }"
      >
        <i class="nbi-icon nbi-plus"></i>
      </v-btn>
    </h2>

    <v-card class="pb-5" outlined v-if="!loading">
      <v-data-table
        v-if="backends.length"
        class="row-pointer"
        :items="backends"
        :disable-pagination="true"
        :hide-default-footer="true"
        :headers="columns"
      >
        <template v-slot:[`item.isEnabled`]="{ item }">
          <span v-if="item.isEnabled" class="success--text">Yes</span>
          <span v-else class="secondary--text">No</span>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <router-link :to="{ name: 'backend', params: { type: 'id', id: `${item.id}` } }">
            {{ item.name }}
          </router-link>
        </template>
      </v-data-table>
    </v-card>

    <v-skeleton-loader type="table-thead, table-row-divider@3" v-if="loading"></v-skeleton-loader>

    <confirm-dialog ref="confirm"></confirm-dialog>
  </div>
</template>


<script>
import { PlatformAPI } from "../http";
export default {
  data() {
    return {
      backends: [],
      loading: false,
      columns: [
        { text: "Enabled", value: "isEnabled", width: "100px", align: "center" },
        { text: "Name", value: "name" },
        { text: "Endpoint", value: "endPoint" },
        { text: "Devices", value: "deviceCount" },
      ],
    };
  },

  async mounted() {
    await this.getBackends();
  },

  methods: {
    async getBackends() {
      try {
        this.loading = true;
        const response = await PlatformAPI.get(`/api/backends`);

        if (response && response.data) this.backends = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
