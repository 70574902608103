import store from '@/store';

// Octal codes are not allowed in strict mode.
// Hence the hexadecimal escape sequence
export const TermColors = {
    Red: '\x1B[1;31m',
    Green: '\x1B[1;32m',
    Purple: '\x1B[1;35m',
    White: '\x1B[1;37m',
    Yellow: '\x1B[1;33m',
    Reset: '\x1B[0m',
  };
  
  export const SHELL_PROMPT = () => TermColors.Yellow + `${(store.state.authenticated_user.firstName || 'anonymous').toLowerCase()}@sentinel:~$ ` + TermColors.Reset;