import axios from "axios";
import router from "./router";

var getHostname = () => {
  // // host = 'sentinel.dgwo3r7k.on-eva.io';
  // // return host;
  // var host = (process.env.NODE_ENV === 'production') ? `${window.location.hostname}:${window.location.port}` : "office.newblack.io:9999"

  // if (!store.state.runningLocal) {
  //   //   console.log('running as server');
  //   host = (process.env.NODE_ENV === 'production') ? `${window.location.hostname}:${window.location.port}` : "office.newblack.io:1337"
  //   //   console.log(host);
  // }
  var host =
    process.env.NODE_ENV === "production"
      ? `${window.location.hostname}:${window.location.port}`
      : "office.newblack.io:1337";
  return host;
};
var getBaseURL = () => "//" + getHostname();

var getAccessToken = (store) => {
  let user = store.state.authenticated_user;
  console.log(user);
  if (user && user.token) {
    return "Bearer " + user.token;
  } else {
    return "";
  }
};

const instance = axios.create({
  baseURL: getBaseURL(),
  headers: {
    "Cache-Control": "no-cache",
  },
});

export const PlatformAPI = instance;

export const AuthenticationPlugin = (store) => {
  instance.interceptors.request.use((config) => {
    config.baseURL = getBaseURL();
    var token = getAccessToken(store);
    if (token) config.headers["Authorization"] = token;
    return config;
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error && error.response && error.response.status === 401) {
        store.commit("setLoggedInUser", null);

        router.push({
          name: "login",
          query: {
            ...(window.location.pathname.length > 1 &&
              window.location.pathname != "/login" && { redirect: window.location.pathname }),
          },
        });
      } else {
        if (!error.response) {
          store.state.offline = true;
          return Promise.reject(new Error("API connection failure"));
        }
        if (error && error.response && error.response.status === 400) {
          return Promise.reject(new Error("Invalid credentials"));
        }
        return Promise.reject(error);
      }
    }
  );
};

export const WSAPI = {
  url() {
    return (process.env.NODE_ENV === "production" ? "https" : "http") + "://" + getHostname();
  },
  wsUrl() {
    return (process.env.NODE_ENV === "production" ? "wss" : "ws") + "://" + getHostname();
  },
  version() {
    return "v2";
  },
};
