<template>
  <div style="max-width: 1600px">
    <h2 class="mb-5">
      System Overview
      <v-spacer></v-spacer>
    </h2>

    <v-row justify="space-between">
      <v-col>
        <v-card class="pb-5" outlined>
          <v-card-title>
            Devices
            <v-spacer></v-spacer>
            <v-btn to="/map" outlined color="secondary" class="v-btn-icon float-right ml-2">
              <i class="nbi-icon nbi-network"></i>
            </v-btn>
            <v-btn outlined class="v-btn-icon float-right ml-2" color="secondary" to="/devices/new">
              <i class="nbi-icon nbi-plus"></i>
            </v-btn>
          </v-card-title>
          <v-data-table
            :items="filteredDevices"
            item-key="id"
            :headers="columns"
            :items-per-page="20"
            :footer-props="{
              'disable-items-per-page': true,
            }"
            :loading="loading"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            class="row-pointer"
          >
            <template v-slot:item.state="{ item }">
              <i
                class="nbi-icon nbi-sentinel"
                :class="deviceIcon(item) + ' ' + deviceColor(item)"
              ></i>
            </template>

            <template v-slot:item.name="{ item }">
              <router-link :to="{ name: 'device', params: { type: 'id', id: `${item.id}` } }">
                {{ item.name }}
              </router-link>
            </template>

            <template v-slot:item.organizationUnit="{ item }">
              <span v-if="item.organizationUnit">{{ item.organizationUnit.name }}</span>
              <span v-else-if="item.isFallback">
                <i class="secondary--text">fallback</i>
              </span>
              <span v-else></span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col class="col-lg-3">
        <v-card class="pb-5" outlined>
          <v-card-title>Filters</v-card-title>

          <v-card-text>
            <v-text-field
              v-model="search"
              prepend-inner-icon="nbi-icon nbi-search"
              label="Search..."
              flat
              outlined
              solo
              hide-details
              clearable
              clear-icon="nbi-icon nbi-xmark"
              class="mb-5 no-label"
            ></v-text-field>

            <h4>Device State</h4>

            <v-radio-group v-model="selectedConnectionState">
              <v-radio :key="-1" value="all" label="All"></v-radio>
              <v-radio
                v-for="(item, index) in connectionStates"
                :key="index"
                :label="item.name"
                :value="item.value"
              ></v-radio>
            </v-radio-group>

            <h4>Device Type</h4>

            <v-radio-group v-model="selectedDeviceType">
              <v-radio :key="-1" value="all" label="All"></v-radio>
              <v-radio
                v-for="(item, index) in deviceTypes"
                :key="index"
                :label="item.name"
                :value="item.value"
              ></v-radio>
            </v-radio-group>
            <v-autocomplete
              label="Country"
              v-model="country"
              clearable
              outlined
              no-data-text="No countries found"
              :items="countries"
              item-value="code"
              item-text="name"
            ></v-autocomplete>

            <v-select
              v-if="backends.length > 1"
              outlined
              label="Backend"
              :items="backends"
              item-value="id"
              item-text="name"
              v-model="selectedBackendId"
              @change="refresh"
              append-icon="nbi-icon nbi-chevron-down"
            ></v-select>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showDeviceEditor" width="600">
      <DeviceEditorCard v-bind:item="selected" v-on:close-dialog="closeDialog"></DeviceEditorCard>
    </v-dialog>
  </div>
</template>
<style>
.v-treeview-node {
  cursor: pointer;
}
</style>

<script>
import { PlatformAPI, WSAPI } from "../http";

import DeviceEditorCard from "./device-list-device";
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
import { mapState } from "vuex";

var AlwaysReconnectPolicy = (function () {
  function AlwaysReconnectPolicy() {}

  AlwaysReconnectPolicy.prototype.nextRetryDelayInMilliseconds = function (retryContext) {
    return retryContext.elapsedMilliseconds < 60000 ? 2500 : 15000;
  };

  return AlwaysReconnectPolicy;
})();

export default {
  components: {
    DeviceEditorCard,
  },
  data() {
    return {
      connection: null,
      loading: false,
      // tree: [],
      selectedBackendId: null,
      active: [],
      open: [],
      search: "",
      caseSensitive: false,
      country: null,
      backends: [],

      connectionStates: [
        // { value: "identifying", name: "Identifying" },
        { value: "connected", name: "Connected" },
        { value: "disconnected", name: "Disconnected" },
      ],
      selectedConnectionState: "all",
      deviceTypes: [
        { value: "unassigned", name: "Unassigned" },
        { value: "assigned", name: "Assigned" },
        { value: "fallback", name: "Fallback" },
      ],
      selectedDeviceType: "all",

      selected: null,
      showDeviceEditor: false,
      devices: [],
      columns: [
        { text: "State", value: "state", width: "85px", align: "center" },
        { text: "Name", value: "name" },
        { text: "Organization Unit", value: "organizationUnit" },
        { text: "Country", value: "organizationUnit.country.name" },
        { text: "Serial #", value: "serialNumber" },
        { text: "Client Version", value: "clientVersion" },
      ],
      sortBy: "state",
      sortDesc: false,
    };
  },
  async mounted() {
    await this.refresh();

    if (this.$route.query.q) {
      setTimeout(() => {
        this.search = this.$route.query.q;
      }, 500);
    }

    var self = this;

    this.connection = new HubConnectionBuilder()
      .withUrl(WSAPI.url() + `/ws/${WSAPI.version()}/ui`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        //accessTokenFactory: () => "1234",
         accessTokenFactory: () => self.$store.state.authenticated_user.token,
      })
      .withAutomaticReconnect(new AlwaysReconnectPolicy())
      .build();

    this.connection.on("StatusUpdate", (data) => self.updateDevice(data));

    this.connection
      .start()
      .then(() => console.log("connected"))
      .catch((err, a, b) => {
        // console.error(err);
        // debugger;

        // if (err.statusCode == 401) {
        self.$store.commit("setLoggedInUser", null);

        self.$router.push({
          name: "login",
        });
        //}
      });
  },

  beforeDestroy() {
    this.connection && this.connection.stop();
  },
  computed: {
    ...mapState(["countries"]),
    filteredDevices() {
      return this.devices.filter((item) => {
        // return item.serialNumber == "A123456" || item.name =='test1000';
        return (
          (this.selectedConnectionState == "all" || this.selectedConnectionState == item.state) &&
          (this.selectedDeviceType == "all" ||
            (item.isFallback && this.selectedDeviceType == "fallback") ||
            (this.selectedDeviceType == "assigned" &&
              !item.isFallback &&
              item.organizationUnit != null) ||
            (this.selectedDeviceType == "unassigned" &&
              !item.isFallback &&
              item.organizationUnit == null)) &&
          (!this.country ||
            (item.organizationUnit && this.country == item.organizationUnit.countryId)) &&
          (!this.search ||
            (item.backendInstanceId && item.backendInstanceId.indexOf(this.search) > -1) ||
            (item.serialNumber && item.serialNumber.indexOf(this.search) > -1) ||
            (item.managementId && item.managementId.indexOf(this.search) > -1) ||
            (item.name && item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1) ||
            (item.organizationUnit &&
              item.organizationUnit.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1) ||
            (item.id && item.id.indexOf(this.search) > -1))
        );
      });
    },
  },

  methods: {
    closeDialog() {
      this.showDeviceEditor = false;
      this.selected = null;
    },
    updateDevice(data) {
      console.log(data);
      var device = this.devices.filter((x) => x.id == data.deviceId);
      if (device.length) device[0].state = data.state;
    },
    activeChanged() {
      if (
        this.$route.params["type"] != this.selected.type ||
        this.$route.params["id"] != this.selected.id
      ) {
        const route = this.$router.resolve({
          name: "system",
          params: { type: this.selected.type, id: this.selected.id },
        });
        history.replaceState(null, "", route.href);
      }
      this.selected.type &&
        this.selected.id &&
        this.setActiveNode(this.selected.type, this.selected.id);
    },
    async deviceUpdated() {
      await this.refresh();
    },
    async deviceDeleted() {
      this.active = [];

      const route = this.$router.resolve({
        name: "system",
      });
      history.replaceState(null, "", route.href);
      await this.refresh();
    },

    deviceIcon(item) {
      return item.isFallback ? "nbi-sentinel-global" : "nbi-sentinel";
    },

    deviceColor(item) {
      //if (item.state == "relayed" && item.isAuthorized) return "blue--text";

      if (item.state == "connected") return "green--text";

      if (item.state == "disconnected") return "secondary--text";
      if (item.state == "identifying") return "yellow--text";

      return null;
    },
    async refresh() {
      try {
        this.loading = true;

        await PlatformAPI.get("/api/backends").then((response) => {
          if (response && response.data) this.backends = response.data;
          if (this.backends.length && this.selectedBackendId == null)
            this.selectedBackendId = this.backends[0].id;

          PlatformAPI.get(`/api/system/overview`, {
            params: { backendId: this.selectedBackendId },
          }).then(
            (response) => {
              if (response && response.data) this.devices = response.data;
              // if (this.tree) {
              //   if (this.$route.params["type"] && this.$route.params["id"]) {
              //     this.setActiveNode(this.$route.params["type"], this.$route.params["id"]);
              //   }
              // }
            },
            () => {
              this.devices = [];
            }
          );
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

