import VueRouter from "vue-router";

import Login from "./components/login";
import Backends from "./components/backends";
import Backend from "./components/backend";
import Users from "./components/users";
import User from "./components/user";
import Style from "./components/style";
//import DeviceTree from './components/device-tree';
import DeviceList from "./components/device-list";
import DeviceEditor from "./components/device-list-device";
import DeviceMap from "./components/device-map";
import Settings from "./components/settings";
import Setup from "./components/setup";
import SetupUser from "./components/setup-user";
import OnboardDevice from "./components/onboard-device";

import store from "./store";

const router = new VueRouter({
  mode: "history",
  base: __dirname,
  routes: [{
      name: "login",
      path: "/login",
      component: Login,
      meta: {
        fullScreen: true,
        navigationEnabled: false,
      },
    },
    {
      path: "/devices/new",
      component: OnboardDevice,
      name: "onboard-device",
    },
    {
      name: "device",
      path: "/devices/:id",
      component: DeviceEditor,
      meta: {
        title: "Device Editor",
      },
    },
    {
      name: "system",
      path: "/system",
      component: DeviceList,
      meta: {
        title: "System Overview",
      },
    },

    {
      name: "map",
      path: "/map",
      component: DeviceMap,
      meta: {
        title: "Device Map",
      },
    },

    // {
    //   path: '/devices/new',
    //   component: CreateDevice,
    //   name: 'create-device'
    // },

    {
      path: "*",
      redirect: "/",
    },
    {
      path: "/",
      redirect: "/system",
    },
    {
      path: "/settings",
      component: Settings,
      name: "settings",
    },
    {
      path: "/setup",
      component: Setup,
      name: "setup",
      meta: {
        fullScreen: true,
        navigationEnabled: false,
      },
    },
    {
      path: "/setup/user",
      component: SetupUser,
      name: "setup-user",
      meta: {
        fullScreen: true,
        navigationEnabled: false,
      },
    },

    {
      name: "style",
      path: "/style",
      component: Style,
    },

    {
      path: "/users",
      name: "users",
      component: Users,
      meta: {
        title: "Users",
      },
    },

    {
      name: "user",
      path: "/users/:id",
      component: User,
      meta: {
        title: "User",
        parents: ["users"],
      },
    },
    {
      name: "backends",
      path: "/backends/",
      component: Backends,
      meta: {
        title: "Backends",
      },
    },
    {
      name: "backend",
      path: "/backends/:id",
      component: Backend,
      meta: {
        title: "Backend",
        parents: ["backends"],
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login", "/setup/user"];
  const authRequired = !publicPages.includes(to.path);

  if (!store.state.offline && store.state.platform.users < 1) {
    if (to.path != "/setup/user") next({
      path: "/setup/user"
    });
    else next();
  } else {
    const loggedIn = store.state.authenticated_user && store.state.authenticated_user.token;

    console.log("validating auth");

    if (authRequired && !loggedIn) {
      router.push({
        name: "login",
        query: {
          ...(window.location.pathname.length > 1 &&
            window.location.pathname != "/login" && {
              redirect: window.location.pathname
            }),
        },
      });
    } else {
      if (to.path != "/setup" && to.path != "/login" && store.state.platform.backends < 1 && !store.state.offline) {
        router.push({
          path: "/setup",
        });
      } else next();
    }
  }
  // if (to.path != "/users/new" && store.state.platform.users < 8 && !store.state.offline) {
  //   // console.log('lala');
  //   next({ path: "/setup/user" });
  // } else next();

  // const loggedIn = store.state.authenticated_user && store.state.authenticated_user.token;
  // if (authRequired && !loggedIn && to.path != "/login") {
  //   router.push({
  //     name: "login",
  //     query: {
  //       ...(window.location.pathname.length > 1 && { redirect: window.location.pathname }),
  //     },
  //   });
  // } else {
  //   if (to.path != "/setup" && store.state.platform.backends < 1 && !store.state.offline) {
  //     router.push({
  //       path: "/setup",
  //     });
  //   } else next();
  // }
});

export default router;