<template>
  <div>
    <form v-on:submit.prevent="lookupDevice">
      <v-card-text>
        Enter the serial number you find on the physical Sentinel device (AXXXXXX)
      </v-card-text>
      <v-card-text>
        <v-text-field
          outlined
          v-model="device.serialNumber"
          label="Serial Number"
          required
          :rules="rules.serialNumber"
          :disabled="!canLookup || lookingUp"
          v-on:keyup.enter="lookupDevice"
        ></v-text-field>
      </v-card-text>

      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn outlined class="primary" @click="lookupDevice" :disabled="!canLookup">
          <span>Lookup Device</span>
          <v-progress-circular
            v-if="lookingUp"
            indeterminate
            :size="18"
            :width="2"
            class="ml-3"
            color="white"
          ></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </form>

    <div class="mt-5">
      <v-card-title>Device Information</v-card-title>
      <v-card-text v-if="lookupDone && this.device.managementId == null">
        <i class="v-icon nbi-icon nbi-warning error--text mr-5"></i>
        Cannot find a online device with this serial number
      </v-card-text>
      <v-card-text v-if="lookupDone && device.managementId">
        <!-- <i class="nbi-icon nbi-sentinel" style="font-size: 4rem"></i> -->
        <v-row class="text-left">
          <v-col class="text-left mr-4" cols="3" style="color: #6b757c">ID:</v-col>
          <v-col>{{ device.managementId }}</v-col>
        </v-row>
        <v-row class="text-left">
          <v-col class="text-left mr-4" cols="3" style="color: #6b757c">Serial Number:</v-col>
          <v-col>{{ device.serialNumber }}</v-col>
        </v-row>
        <v-row class="text-left">
          <v-col class="text-left mr-4" cols="3" style="color: #6b757c">Created:</v-col>
          <v-col>{{ formatDateTime(device.created_ts) }}</v-col>
        </v-row>
        <v-row class="text-left" v-if="device.network_interfaces">
          <v-col class="text-left mr-4" cols="3" style="color: #6b757c">Network Interfaces:</v-col>
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>MAC</th>
                    <th>IPv4</th>
                    <th>IPv6</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="iface in device.network_interfaces" v-bind:key="iface.name">
                    <td>{{ iface.name }}</td>
                    <td>
                      <span :ref="iface.name + '_mac'">{{ iface.mac }}</span>
                      <v-btn text icon @click="copyElementText(iface.name + '_mac')">
                        <i class="nbi-icon nbi-duplicate small ml-1"></i>
                      </v-btn>
                    </td>
                    <td>{{ iface.ipv4 }}</td>
                    <td>{{ iface.ipv6 }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <div v-if="lookupDone && this.device.managementId">
        <v-card-text v-if="lookupDone && this.device.status == 'accepted'">
          <i class="v-icon nbi-icon nbi-warning error--text mr-5"></i>
          This device is already boarded, please find in in the
          <router-link
            :to="{
              name: 'device',
              params: { type: 'id', id: `${this.device.id}` },
            }"
          >
            System Overview
          </router-link>
        </v-card-text>
        <v-card-text v-else>
          <v-card-title>Attach this device to</v-card-title>
          <v-select
            v-if="backends.length > 1"
            :items="backends"
            item-text="name"
            item-value="id"
            outlined
            label="Select your backend"
            v-model="backend"
            return-object
            @change="loadOrganizationUnits"
            :disabled="device.id != null || boarding"
          ></v-select>
          <v-autocomplete
            label="Attached to"
            clearable
            outlined
            v-model="device.organizationUnitId"
            no-data-text="No Organization Units found"
            :items="organizationUnits"
            :disabled="device.isFallback || device.id != null || boarding"
            item-value="id"
            :item-text="formatOUName"
          ></v-autocomplete>
          <span v-if="boardError">
            <i class="v-icon nbi-icon nbi-warning error--text mr-5"></i>
            {{ boardError }}
          </span>
        </v-card-text>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined class="primary" @click.native="boardDevice" :disabled="!canBoard">
          Board this Device
          <v-progress-circular
            v-if="boarding"
            indeterminate
            :size="18"
            :width="2"
            class="ml-3"
            color="white"
          ></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </div>
  </div>
</template>
<script>
import { PlatformAPI } from "../http";
import { mapState, mapMutations } from "vuex";

export default {
  props: {
    device: {
      type: Object,
      default: () => {
        return { id: null, serialNumber: null, managementId: null, network_interfaces: [] };
      },
    },
  },

  data() {
    return {
      organizationUnits: [],
      backends: [],
      backend: null,
      lookingUp: false,
      lookupDone: false,
      boarding: false,
      valid: false,
      boardError: null,
      rules: {
        serialNumber: [
          (v) => !!v || "Serial Number is required",
          (v) =>
            /[A-Za-z]{1}[0-9]{6}$/i.test(v) ||
            "Please enter a serial number in the form op A123456 (1 letter, 6 digits)",
        ],
      },
    };
  },
  computed: {
    ...mapState(["platform"]),

    canLookup() {
      return !this.device.id;
    },
    canComplete() {
      return this.device.id;
    },
    canBoard() {
      return (
        this.lookupDone &&
        this.device.managementId &&
        this.device.status != "accepted" &&
        this.device.organizationUnitId &&
        this.backend &&
        this.backend.id &&
        !this.device.id
      );
    },
  },

  methods: {
    ...mapMutations(["setSnack"]),
    async loadOrganizationUnits() {
      const ouResponse = await PlatformAPI.get("/api/ou", {
        params: { backendId: this.backend.id },
      });
      this.organizationUnits = ouResponse.data;
    },
    completeSetup() {},
    async boardDevice() {
      try {
        this.boarding = true;
        this.boardError = null;
        const response = await PlatformAPI.post(`/api/onboarding/board-device`, {
          name: this.device.serialNumber,
          managementId: this.device.managementId,
          backendId: this.backend.id,
          organizationUnitId: this.device.organizationUnitId,
          isFallback: this.isFallback,
          serialNumber: this.device.serialNumber,
        });

        this.$emit("device-saved", response.data);
      } catch (err) {
        this.boardError = err.response.data.message;
      } finally {
        this.boarding = false;
      }
    },

    async loadData() {
      let response = await PlatformAPI.get("/api/backends");
      this.backends = response.data;

      if (this.backends.length == 1) {
        this.backend = this.backends[0];
        await this.loadOrganizationUnits();
      }
      await this.$store.dispatch("refreshPlatformInformation");
    },

    async lookupDevice() {
      try {
        this.boardError = null;
        this.lookingUp = true;
        let response = await PlatformAPI.post(`/api/onboarding/lookup`, {
          serialNumber: this.device.serialNumber,
        });

        if (response.status == 200) {
          this.device.managementId = response.data.id;
          this.device.created_ts = response.data.created_ts;
          this.device.status = response.data.status;
          this.device.network_interfaces = response.data.network_interfaces;
          this.device.id = response.data.identity_data["device_id"];
          await this.loadData();
        }
      } catch (err) {
        if (err.response.status == 404)
          this.device = {
            serialNumber: this.device.serialNumber,
            managementId: null,
          };
      } finally {
        this.lookupDone = true;
        this.lookingUp = false;
      }
    },

    copyElementText(el) {
      var e = this.$refs[el][0];

      navigator.clipboard.writeText(e.value || e.innerText).then(
        () => {
          this.setSnack({
            message: "Copied to clipboard",
            color: "success",
          });
        },
        (err) => {
          this.setSnack({
            message: "Error copying to clipboard : " + err,
            color: "error",
          });
        }
      );
    },
  },
};
</script>